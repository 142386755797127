<h3 class="mb-3">{{ 'APP.TITLE' | translate }}</h3>
<div class="row mb-3 justify-content-center">
  <div class="col-12">
    <h3 class="mb-3">{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT_WITHDRAWAL.TITLE' | translate }}</h3>
    <p class="mb-3">{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT_WITHDRAWAL.TEXT' | translate }}</p>

    <button nv-button type="submit" [routerLink]="[backToStartLink]">
      {{ 'BTN.BACK_TO_START' | translate }}
    </button>
  </div>
</div>
