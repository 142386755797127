<nv-modal #reentryNotificationModal [isClosable]="false">
  <ng-template modalContent>
    <div class="row justify-content-center align-items-center">
      <div class="col-12 text-center mb-2">
        <p id="logoutSuccessMessage">{{ 'PRICING_REENTRY_MODAL.TEXT' | translate }}</p>
      </div>

      <button nv-button class="btn btn-secondary mt-2 px-5" (click)="reentryNotificationModal.close()">
        {{ 'BTN.NEXT' | translate }}
      </button>
    </div>
  </ng-template>
</nv-modal>
