<form nv-form [formGroup]="loginForm" (submit)="onSubmit($event)">
  <div class="row mb-2">
    <div class="form-group col-12">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="username"
          data-cy="username"
          name="username"
          formControlName="username"
          autocomplete="username"
          [placeholder]="'LABEL.EMAIL' | translate"
        />
        <label for="username">{{ 'LABEL.EMAIL' | translate }}</label>
        <nv-error
          id="username-error-required"
          data-cy="username-error-required"
          *ngIf="loginForm.controls['username'].errors?.required && loginForm.controls['username'].touched"
          >{{ 'ERROR.REQUIRED' | translate }}
        </nv-error>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="form-floating">
        <input
          nvInput
          type="password"
          id="password"
          data-cy="password"
          name="password"
          formControlName="password"
          autocomplete="current-password"
          [placeholder]="'LABEL.PASSWORD' | translate"
        />
        <label for="password">{{ 'LABEL.PASSWORD' | translate }}</label>
        <div class="row">
          <div class="col-6">
            <nv-error
              id="password-error-required"
              data-cy="password-error-required"
              class="d-block"
              *ngIf="loginForm.controls['password'].errors?.required && loginForm.controls['password'].touched"
              >{{ 'ERROR.REQUIRED' | translate }}
            </nv-error>
            <nv-error
              id="login-error"
              data-cy="login-error"
              class="d-block"
              *ngIf="loginForm.errors?.credentials as credentialsError"
              >{{ 'ERROR.' + credentialsError | translate }}
            </nv-error>
          </div>

          <small class="form-text text-end col-6">
            <nv-link *ngIf="kupoUrl" href="{{ kupoUrl }}/passwort-vergessen">{{
              'BTN.FORGOT_CREDENTIALS' | translate
            }}</nv-link>
            <nv-link *ngIf="!kupoUrl" link="/passwort-vergessen">{{ 'BTN.FORGOT_CREDENTIALS' | translate }}</nv-link>
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        type="submit"
        nv-button
        data-cy="login-button-mobile"
        class="btn-block d-block d-md-none m-0"
        [disabled]="!loginForm.valid"
      >
        {{ 'BTN.LOGIN' | translate }}
      </button>
      <button
        type="submit"
        nv-button
        data-cy="login-button-desktop"
        class="d-none d-md-inline-block px-3"
        [disabled]="!loginForm.valid"
      >
        {{ 'BTN.LOGIN' | translate }}
      </button>
    </div>
  </div>
</form>
