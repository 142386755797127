<div *ngIf="visible$ | async" class="login-teaser-success shadow mb-3">
  <div class="row position-relative">
    <div class="col-2 col-md-1 d-flex justify-content-center">
      <div>
        <i class="fas fa-info-circle nv-info"></i>
      </div>
    </div>
    <div class="col-10 col-md-11">
      <p class="mb-0">
        {{ 'APP.LOGIN_TEASER_SUCCESS.MESSAGE_1' | translate }}
      </p>
      <p>
        {{ 'APP.LOGIN_TEASER_SUCCESS.MESSAGE_2' | translate }}
      </p>

      <button type="button" class="btn btn-light nv-dismiss py-1" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true" class="far fa-times"></span>
      </button>
    </div>
  </div>
</div>
