<form (submit)="onSubmit($event)">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 text-secondary text-center display-1"><i class="fal fa-check-circle"></i></div>
    <div class="col-12 text-center mb-2">
      <h2>{{ 'PASSWORD_CHANGED.TITLE' | translate }}</h2>
      <p>{{ 'PASSWORD_CHANGED.MESSAGE' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="d-flex justify-content-center col-12 text-md-end text-center">
      <button nv-button class="col-12 col-md-4" type="submit">{{ 'PASSWORD_CHANGED.BTN.SUBMIT' | translate }}</button>
    </div>
  </div>
</form>
