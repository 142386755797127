<nv-card>
  <h4>{{ 'LOGIN-DETAILS.USERNAME_CHANGE_TITLE' | translate }}</h4>
  <form nv-form class="row" [formGroup]="usernameChangeForm" (submit)="openEmailVerification($event)">
    <div class="form-group col-12 col-xl-6" *ngIf="usernameChangeForm.controls['email'] as email">
      <nv-user-email [value]="initialEmail" (formReady)="formInitialized('email', $event)"></nv-user-email>
    </div>
    <div class="form-group col-12 col-xl-6" *ngIf="usernameChangeForm.controls['currentPassword'] as currentPassword">
      <nv-password
        formControlName="currentPassword"
        id="currentPassword"
        [placeholder]="'LABEL.PASSWORD' | translate"
        ngDefaultControl
      ></nv-password>
      <nv-error
        *ngIf="currentPassword.errors?.required && currentPassword.touched"
        id="currentPassword-error-required"
        data-cy="currentPassword-error-required"
      >
        {{ 'LOGIN-DETAILS.ERROR.REQUIRED' | translate }}
      </nv-error>
      <nv-error *ngIf="currentPassword.errors?.invalidPassword" id="invalid-password" data-cy="invalid-password">
        {{ 'LOGIN-DETAILS.ERROR.INVALID_PASSWORD' | translate }}</nv-error
      >
    </div>
    <div class="col-12 d-flex flex-wrap justify-content-md-end">
      <a
        [routerLink]="loginDetailPath"
        class="btn-light mt-1 mt-md-0 col-12 col-md-auto order-last order-md-first"
        nv-button
        data-cy="cancel"
      >
        {{ 'BTN.CANCEL' | translate }}
      </a>
      <button class="col-12 ms-md-1 col-md-4" nv-button data-cy="update-username" type="submit">
        {{ 'BTN.SAVE' | translate }}
      </button>
    </div>
  </form>
</nv-card>

<nv-user-email-verification #verificationComponent (successfulVerification)="updateUsername()">
</nv-user-email-verification>

<nv-modal #successModal>
  <ng-template modalContent>
    <div class="row justify-content-center align-items-center" data-cy="success-modal-content">
      <div class="col-12 text-secondary text-center display-1">
        <i class="fal fa-lock-alt"></i>
      </div>
      <div class="col-12 text-center mb-2">
        <p>{{ 'LOGIN-DETAILS.USERNAME_CHANGE_SUCCESS' | translate }}</p>
      </div>
    </div>
  </ng-template>
  <ng-content></ng-content>
</nv-modal>
