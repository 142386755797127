<nv-modal #modalVerification>
  <ng-template modalContent>
    <form nv-form [formGroup]="pinCodeForm" (submit)="verifyVerificationCode($event)">
      <div class="row">
        <div class="col-12">
          <h3>{{ 'USER_MANAGEMENT.MODAL.VERIFICATION.TITLE' | translate }}</h3>
          <p
            [innerHTML]="
              'USER_MANAGEMENT.MODAL.VERIFICATION.TEXT' | translate: { email: verifyEmailData.userData.email }
            "
          ></p>
          <div class="row">
            <div class="form-group col-12" *ngIf="pinCodeForm.controls.pinCode as pinCodeControl">
              <nv-pincode formControlName="pinCode"></nv-pincode>
              <p class="row ps-2 pt-2 invalid-feedback" *ngIf="pinCodeControl.errors as errors">
                <span class="d-block" *ngIf="errors.required && pinCodeControl.touched">
                  {{ 'ERROR.REQUIRED' | translate }}
                </span>
                <span class="d-block" *ngIf="errors.invalid && pinCodeControl.touched">
                  {{ 'USER_MANAGEMENT.MODAL.VERIFICATION.ERROR.PIN_INVALID' | translate }}
                </span>
                <span class="d-block" *ngIf="errors.minlength && pinCodeControl.touched">
                  {{ 'USER_MANAGEMENT.MODAL.VERIFICATION.ERROR.MIN_LENGTH' | translate }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isResendPIN" class="row mb-3">
        <div class="col-12">
          <p>
            {{ 'USER_MANAGEMENT.MODAL.VERIFICATION.NOT_RECEIVED' | translate }}
            <span class="btn-link cursor-pointer" (click)="onResendPin()">{{
              'USER_MANAGEMENT.MODAL.VERIFICATION.RESEND' | translate
            }}</span>
          </p>
        </div>
      </div>

      <div *ngIf="isResendPIN" class="row mb-3">
        <div class="col-12">
          <nv-card class="border-primary-2 bg-primary-3">
            <p>
              {{ 'USER_MANAGEMENT.MODAL.VERIFICATION.INFO.RESEND_PIN' | translate }}
            </p>
          </nv-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-between">
          <button nv-button type="button" class="btn btn-light col-12 col-md-auto" color="light" (click)="onBack()">
            <i class="far fa-arrow-left me-1"></i>{{ 'BTN.BACK' | translate }}
          </button>
          <button
            nv-button
            data-cy="modal-submit"
            class="px-3 col-12 col-md-auto mt-1 mt-md-0"
            color="secondary"
            type="submit"
          >
            {{ 'BTN.NEXT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</nv-modal>
