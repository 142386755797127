<form nv-form [formGroup]="registrationForm" (submit)="onSubmit($event)">
  <div class="row mb-3">
    <div class="col-12">
      <nv-card class="border-primary-1">
        <h3>{{ 'USER_MANAGEMENT.PAGE.REGISTRATION.USERNAME.TITLE' | translate }}</h3>
        <p [innerHTML]="'USER_MANAGEMENT.PAGE.REGISTRATION.USERNAME.TEXT' | translate"></p>
        <div class="form-group">
          <div class="form-floating">
            <nv-user-email
              [value]="initialEmail"
              (formReady)="formInitialized('email', $event)"
              (emailUnavailable)="handleMailUniqueness($event)"
            ></nv-user-email>
          </div>
        </div>
        <h3>{{ 'USER_MANAGEMENT.PAGE.REGISTRATION.PASSWORD.TITLE' | translate }}</h3>
        <div class="row">
          <div class="form-group col-12 col-lg-6" *ngIf="registrationForm.controls['password'] as password">
            <nv-password
              formControlName="password"
              id="password"
              [placeholder]="'LABEL.PASSWORD' | translate"
              ngDefaultControl
            ></nv-password>
            <nv-error
              id="password-error-required"
              data-cy="password-error-required"
              *ngIf="password.errors?.required && password.touched"
              >{{ 'ERROR.REQUIRED' | translate }}</nv-error
            >
            <nv-error
              id="password-error-pattern"
              data-cy="password-error-pattern"
              *ngIf="password.errors?.minlength && password.touched"
              >{{ 'USER_MANAGEMENT.PAGE.REGISTRATION.ERROR.PATTERN_MIN' | translate }}</nv-error
            >
            <nv-error
              id="password-error-weak"
              data-cy="password-error-weak"
              *ngIf="password.errors?.isWeak && password.touched"
              >{{ 'USER_MANAGEMENT.PAGE.REGISTRATION.ERROR.PATTERN_WEAK' | translate }}</nv-error
            >
            <nv-error
              id="password-error-specialchars"
              data-cy="password-error-specialchars"
              *ngIf="password.errors?.specialChar && password.touched"
              >{{
                'USER_MANAGEMENT.PAGE.REGISTRATION.ERROR.PATTERN_SPECIALCHARS'
                  | translate: { allowedSpecialCharacters: specialChars }
              }}</nv-error
            >
          </div>
          <div
            class="form-group col-12 col-lg-6"
            *ngIf="registrationForm.controls['passwordConfirmation'] as passwordConfirmation"
          >
            <div class="form-floating">
              <nv-password
                formControlName="passwordConfirmation"
                id="password-confirmation"
                [placeholder]="'LABEL.PASSWORD_CONFIRM' | translate"
                ngDefaultControl
              ></nv-password>
              <nv-error
                id="password-confirmation-error-required"
                data-cy="password-confirmation-error-required"
                class="d-block"
                *ngIf="passwordConfirmation.errors?.required && passwordConfirmation.touched"
                >{{ 'ERROR.REQUIRED' | translate }}</nv-error
              >
              <nv-error
                id="password-confirmation-error-pattern"
                data-cy="password-confirmation-error-pattern"
                class="d-block"
                *ngIf="registrationForm.errors?.isEqualPassword && passwordConfirmation.touched"
                >{{ 'USER_MANAGEMENT.PAGE.REGISTRATION.ERROR.PATTERN_MATCH' | translate }}</nv-error
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <nv-password-strength
              [password]="registrationForm.controls.password.value"
              (passwordStrengthChange)="onPassed($event)"
            >
              <span security>{{
                'LIB.PASSWORD_STRENGTH.SECURITY'
                  | translate: { strength: 'LIB.PASSWORD_STRENGTH.STRENGTH.' + passwordStrength | translate }
              }}</span>
              <span securityNote>{{ 'LIB.PASSWORD_STRENGTH.SECURITY_NOTE' | translate }}</span>
            </nv-password-strength>
          </div>
          <div class="col-12">
            <nv-card class="bg-primary-3 border-primary-2 my-2 p-2 p-md-3">
              <p>
                {{ 'USER_MANAGEMENT.PAGE.REGISTRATION.TERMS_OF_USE.TEXT1' | translate }}
                <nv-link href="{{ baseUrl }}/assets/documents/evox_Kundenportal_Nutzungsbedingungen.pdf">{{
                  'USER_MANAGEMENT.PAGE.REGISTRATION.TERMS_OF_USE.LINK' | translate
                }}</nv-link>
                {{ 'USER_MANAGEMENT.PAGE.REGISTRATION.TERMS_OF_USE.TEXT2' | translate }}
              </p>
              <div class="form-group col-12">
                <nv-checkbox formControlName="trustedShops">{{
                  'USER_MANAGEMENT.PAGE.REGISTRATION.TRUSTED_SHOPS' | translate
                }}</nv-checkbox>
              </div>
            </nv-card>
          </div>
        </div>
      </nv-card>
    </div>
  </div>
  <nv-wizard-page-navigation
    [forwardLink]="nextLink"
    [forwardTitle]="'BTN.EMAIL_CONFIRM' | translate"
    [backLink]="previousLink"
    [backTitle]="'BTN.BACK' | translate"
  ></nv-wizard-page-navigation>
</form>

<nv-user-email-verification
  #verificationComponent
  (successfulVerification)="onVerificationSuccessful()"
  (pinCodeEmailSent)="onSendPinCode()"
></nv-user-email-verification>
