<nv-card class="mb-3">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 text-secondary text-center display-1"><i class="fal fa-lock-alt"></i></div>
    <div class="col-12 text-center mb-2">
      <h2>{{ 'PASSWORD_RECOVERY.TITLE' | translate }}</h2>
      <p>{{ 'PASSWORD_RECOVERY.MESSAGE' | translate }}</p>
    </div>
    <div class="col-12">
      <form nv-form [formGroup]="passwordRecoveryForm" (submit)="submit($event)">
        <div class="row mb-2">
          <div class="form-group col-12">
            <div class="form-floating">
              <input
                nvInput
                type="text"
                id="email"
                data-cy="email"
                name="email"
                formControlName="email"
                autocomplete="email"
                [placeholder]="'PASSWORD_RECOVERY.LABEL.EMAIL' | translate"
              />
              <label for="email">{{ 'PASSWORD_RECOVERY.LABEL.EMAIL' | translate }}</label>
              <nv-error
                *ngIf="
                  passwordRecoveryForm.controls['email'].errors?.required &&
                  passwordRecoveryForm.controls['email'].touched
                "
                id="email-error-required"
                data-cy="email-error-required"
              >
                {{ 'PASSWORD_RECOVERY.ERROR.REQUIRED' | translate }}
              </nv-error>
              <nv-error
                *ngIf="
                  passwordRecoveryForm.controls['email'].errors?.pattern && passwordRecoveryForm.controls['email'].touched
                "
                id="email-error-pattern"
                data-cy="email-error-pattern"
              >{{ 'PASSWORD_RECOVERY.ERROR.EMAIL_PATTERN' | translate }}
              </nv-error>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="form-group col-12 col-md-6" *ngIf="passwordRecoveryForm.controls['password'] as password">
            <nv-password
              formControlName="password"
              id="password"
              [placeholder]="'PASSWORD_RECOVERY.LABEL.PASSWORD' | translate"
              ngDefaultControl
            ></nv-password>
            <nv-error
              *ngIf="password.errors?.required && password.touched"
              id="password-error-required"
              data-cy="password-error-required"
            >
              {{ 'PASSWORD_RECOVERY.ERROR.REQUIRED' | translate }}
            </nv-error>
            <nv-error
              *ngIf="password.errors?.minlength && password.touched"
              id="password-error-pattern"
              data-cy="password-error-pattern"
            >
              {{ 'PASSWORD_RECOVERY.ERROR.PATTERN_MIN' | translate }}
            </nv-error>
            <nv-error
              *ngIf="password.errors?.isWeak && password.touched"
              id="password-error-weak"
              data-cy="password-error-weak"
            >
              {{ 'PASSWORD_RECOVERY.ERROR.PATTERN_WEAK' | translate }}
            </nv-error>
            <nv-error
              id="password-error-specialchars"
              data-cy="password-error-specialchars"
              *ngIf="password.errors?.specialChar && password.touched"
            >
              {{
                'PASSWORD_RECOVERY.ERROR.PATTERN_SPECIAL_CHARS' | translate: { allowedSpecialCharacters: specialChars }
              }}
            </nv-error>
          </div>
        </div>
        <div
          class="form-group col-12 col-md-6"
          *ngIf="passwordRecoveryForm.controls['passwordConfirmation'] as passwordConfirmation"
        >
          <nv-password
            formControlName="passwordConfirmation"
            id="password-confirmation"
            [placeholder]="'PASSWORD_RECOVERY.LABEL.PASSWORD_CONFIRMATION' | translate"
            ngDefaultControl
          ></nv-password>
          <nv-error
            *ngIf="passwordConfirmation.errors?.required && passwordConfirmation.touched"
            id="password-confirmation-error-required"
            data-cy="password-confirmation-error-required"
            class="d-block"
          >
            {{ 'PASSWORD_RECOVERY.ERROR.REQUIRED' | translate }}
          </nv-error>
          <nv-error
            *ngIf="passwordRecoveryForm.errors?.isEqualPassword && passwordConfirmation.touched"
            id="password-confirmation-error-pattern"
            data-cy="password-confirmation-error-pattern"
            class="d-block"
            >{{ 'PASSWORD_RECOVERY.ERROR.PATTERN_MATCH' | translate }}
          </nv-error>
        </div>

        <div class="row">
          <div class="form-group col-12">
            <nv-password-strength
              [password]="passwordRecoveryForm.controls.password.value"
              (passwordStrengthChange)="onPassed($event)"
            >
              <span security>{{
                'LIB.PASSWORD_STRENGTH.SECURITY'
                  | translate: { strength: 'LIB.PASSWORD_STRENGTH.STRENGTH.' + passwordStrength | translate }
              }}</span>
              <span securityNote>{{ 'LIB.PASSWORD_STRENGTH.SECURITY_NOTE' | translate }}</span>
            </nv-password-strength>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-md-end text-center">
            <button nv-button class="col-12 col-md-auto" type="submit" data-cy="submit-new-password-button">
              {{ 'PASSWORD_RECOVERY.BTN.SEND' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</nv-card>

<nv-modal #modalPasswordChanged (closed)="onModalPasswordChangedClosed()">
  <ng-template modalContent>
    <nv-user-password-changed (submit)="submitModalPasswordChanged()"></nv-user-password-changed>
  </ng-template>
</nv-modal>

<nv-modal #modalLinkExpired (closed)="onModalLinkExpiredClosed()">
  <ng-template modalContent>
    <form nv-form (submit)="submitModalLinkExpired($event)">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 text-secondary text-center display-1"><i class="fal fa-exclamation-circle"></i></div>
        <div class="col-12 text-center mb-2">
          <h2>{{ 'PASSWORD_RECOVERY.LINK_EXPIRED.TITLE' | translate }}</h2>
          <p>{{ 'PASSWORD_RECOVERY.LINK_EXPIRED.MESSAGE' | translate }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button nv-button type="submit" class="btn-block">
            {{ 'PASSWORD_RECOVERY.LINK_EXPIRED.BTN.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</nv-modal>
