<div class="row mb-3 border-primary-1 ms-0 col-12 col-md-8 col-lg-6 px-0">
  <nv-card>
    <div class="justify-content-center justify-content-md-start">
      <div class="text-center text-error">
        <h1 class="mb-2"><i class="fal fa-frown"></i></h1>
        <p class="text-start mb-3">
          {{ contentTxt }}
        </p>
      </div>
      <a class="btn btn-secondary d-flex justify-content-center btn-block" href="{{ hrefLink }}">{{ buttonTxt }}</a>
    </div>
  </nv-card>
</div>
