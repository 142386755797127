import { TrackingConfig } from '@nbg-digital/shared/util-tracking';

export const DKR_PRODUCT_NAME = 'Existenzsicherung|Krebsversicherung|Evo-X';
export const DKR_PRODUCT_GROUP = 'Lebensversicherung';
export const DKR_PRODUCT_TARGET = 'Privatkunden';
export const DKR_PRODUCT_LABEL = 'Krebsversicherung';
export const DKR_BASE_PAGE_NAME = 'EvoX/Produkt/Existenzsicherung/Krebsversicherung';
export const DKR_SESSION_DOMAIN = 'Evo-X';
export const DKR_BASE_PAGE_CATEGORIES = [
  'EvoX',
  '-',
  'Produkte',
  'Existenzsicherung',
  'Krebsversicherung',
  'Antrag',
];

export const DKR_TRACKING_CONFIG: TrackingConfig = {
  basePageName: DKR_BASE_PAGE_NAME,
  basePageCategories: DKR_BASE_PAGE_CATEGORIES,
  productName: DKR_PRODUCT_NAME,
  productGroup: DKR_PRODUCT_GROUP,
  productTarget: DKR_PRODUCT_TARGET,
  productLabel: DKR_PRODUCT_LABEL,
  sessionDomain: DKR_SESSION_DOMAIN
};
