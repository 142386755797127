<button
  nv-button
  variant="outline"
  data-cy="continue-later"
  [nvActionName]="'BTN.CONTINUE_LATER' | translate"
  [dynamic]="true"
  (click)="this.modal.open()"
>
  {{ 'BTN.CONTINUE_LATER' | translate }}
  <nv-modal #modal (closed)="resetShowModal()">
    <ng-template modalContent>
      <ng-container>
        <div class="row" *ngIf="showForm">
          <div class="col-12">
            <h3 class="mb-2">{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.TITLE' | translate }}</h3>
            <p>{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.TEXT' | translate }}</p>
            <p>
              <nv-link [href]="privacyStatementUrl">
                {{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.PRIVACY' | translate }}
              </nv-link>
            </p>
            <form nv-form [formGroup]="agreementForm" (submit)="onSubmit($event)" #form="ngForm">
              <div class="form-group mb-3" *ngIf="agreementForm.controls['email'] as email">
                <div class="form-floating">
                  <input
                    nvInput
                    id="storage-agreement-email"
                    type="email"
                    name="email"
                    data-cy="email"
                    formControlName="email"
                    autocomplete="email"
                    [placeholder]="'LABEL.EMAIL' | translate"
                  />
                  <label for="storage-agreement-email">{{ 'LABEL.EMAIL' | translate }}</label>
                  <nv-error id="email-error-required" *ngIf="email.errors?.required && email.touched">{{
                    'SESSION_MANAGEMENT.PAGE.AGREEMENT.ERROR.REQUIRED' | translate
                  }}</nv-error>
                  <nv-error id="email-error-pattern" *ngIf="email.errors?.pattern && email.touched">{{
                    'ERROR.EMAIL_PATTERN' | translate
                  }}</nv-error>
                </div>
              </div>
              <div *ngIf="mainBroker === MainBroker.Evox">
                <div
                  class="form-group mb-2"
                  *ngIf="appConfigService.config.enableEwe && agreementForm.controls.acceptance as acceptance"
                >
                  <h3 class="mb-2">{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.EWE.TITLE' | translate }}</h3>
                  <nv-checkbox formControlName="acceptance">
                    <p>
                      {{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.EWE.ACCEPTANCE' | translate }}
                    </p>
                    <p>
                      {{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.EWE.ACCEPTANCE_SUB' | translate }}
                    </p>
                    <nv-link [href]="imprintUrl">
                      {{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.IMPRINT' | translate }}
                    </nv-link>
                  </nv-checkbox>
                </div>
              </div>
              <p class="text-center mt-3">
                <button
                  nv-button
                  type="submit"
                  color="secondary"
                  data-cy="reentry-submit"
                  [disabled]="!agreementForm.valid"
                >
                  {{ 'BTN.REENTRY' | translate }}
                </button>
              </p>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="!showForm">
          <div class="col-12">
            <h3 class="mb-2">{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.SUCCESS.TITLE' | translate }}</h3>
            <p>{{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.SUCCESS.TEXT' | translate }}</p>
            <p class="mb-2">
              <nv-link [href]="privacyStatementUrl">
                {{ 'SESSION_MANAGEMENT.PAGE.AGREEMENT.PRIVACY' | translate }}
              </nv-link>
            </p>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </nv-modal>
</button>
