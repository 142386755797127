<nv-card>
  <h3>{{ 'LOGIN-DETAILS.TITLE' | translate }}</h3>
  <form nv-form class="row" [formGroup]="passwordChangeForm" (submit)="updateUserData($event)">
    <div class="form-group col-12 col-lg-6">
      <div class="form-floating">
        <input
          nvInput
          type="text"
          id="username"
          data-cy="username"
          formControlName="email"
          autocomplete="username"
          [placeholder]="'LABEL.USERNAME' | translate"
        />
        <label for="username">{{ 'LABEL.USERNAME' | translate }}</label>
      </div>
    </div>
    <div class="form-group col-12 col-lg-6" *ngIf="passwordChangeForm.controls['currentPassword'] as currentPassword">
      <nv-password
        formControlName="currentPassword"
        id="currentPassword"
        [placeholder]="'LABEL.PASSWORD_CURRENT' | translate"
        ngDefaultControl
      ></nv-password>
      <nv-error
        *ngIf="currentPassword.errors?.required && currentPassword.touched"
        id="current-password-error-required"
        data-cy="current-password-error-required"
        class="d-block"
      >
        {{ 'LOGIN-DETAILS.ERROR.REQUIRED' | translate }}
      </nv-error>
      <nv-error
        id="invalid-password"
        data-cy="invalid-password"
        class="d-block"
        *ngIf="currentPassword.errors?.invalidPassword"
      >
        {{ 'LOGIN-DETAILS.ERROR.INVALID_PASSWORD' | translate }}</nv-error
      >
    </div>
    <div class="form-group col-12 col-lg-6" *ngIf="passwordChangeForm.controls['password'] as password">
      <nv-password
        formControlName="password"
        id="password"
        [placeholder]="'LABEL.PASSWORD_NEW' | translate"
        ngDefaultControl
      ></nv-password>
      <nv-error
        *ngIf="password.errors?.required && password.touched"
        id="password-error-required"
        data-cy="password-error-required"
      >
        {{ 'LOGIN-DETAILS.ERROR.REQUIRED' | translate }}
      </nv-error>
      <nv-error
        *ngIf="password.errors?.minlength && password.touched"
        id="password-error-pattern"
        data-cy="password-error-pattern"
      >
        {{ 'LOGIN-DETAILS.ERROR.PATTERN_MIN' | translate }}
      </nv-error>
      <nv-error
        *ngIf="password.errors?.isWeak && password.touched"
        id="password-error-weak"
        data-cy="password-error-weak"
      >
        {{ 'LOGIN-DETAILS.ERROR.PATTERN_WEAK' | translate }}
      </nv-error>
      <nv-error
        *ngIf="password.errors?.specialChar && password.touched"
        id="password-error-special-chars"
        data-cy="password-error-special-chars"
      >
        {{ 'LOGIN-DETAILS.ERROR.PATTERN_SPECIAL_CHARS' | translate: { allowedSpecialCharacters: specialChars } }}
      </nv-error>
      <nv-error
        *ngIf="passwordChangeForm.errors?.isNewPassword && password.touched"
        id="password-error-is-new"
        data-cy="password-error-is-new"
        >{{ 'LOGIN-DETAILS.ERROR.NEW_PASSWORD' | translate }}
      </nv-error>
    </div>
    <div
      class="form-group col-12 col-lg-6"
      *ngIf="passwordChangeForm.controls['passwordConfirmation'] as passwordConfirmation"
    >
      <nv-password
        formControlName="passwordConfirmation"
        id="password-confirmation"
        [placeholder]="'LABEL.PASSWORD_NEW_CONFIRM' | translate"
        ngDefaultControl
      ></nv-password>
      <nv-error
        *ngIf="passwordConfirmation.errors?.required && passwordConfirmation.touched"
        id="password-confirmation-error-required"
        data-cy="password-confirmation-error-required"
        class="d-block"
      >
        {{ 'LOGIN-DETAILS.ERROR.REQUIRED' | translate }}
      </nv-error>
      <nv-error
        *ngIf="passwordChangeForm.errors?.isEqualPassword && passwordConfirmation.touched"
        id="password-confirmation-error-pattern"
        data-cy="password-confirmation-error-pattern"
        class="d-block"
        >{{ 'LOGIN-DETAILS.ERROR.PATTERN_MATCH' | translate }}
      </nv-error>
    </div>

    <div class="form-group col-12">
      <nv-password-strength
        [password]="passwordChangeForm.controls.password.value"
        (passwordStrengthChange)="onPassed($event)"
      >
        <span security>{{
          'LIB.PASSWORD_STRENGTH.SECURITY'
            | translate: { strength: 'LIB.PASSWORD_STRENGTH.STRENGTH.' + passwordStrength | translate }
        }}</span>
        <span securityNote>{{ 'LIB.PASSWORD_STRENGTH.SECURITY_NOTE' | translate }}</span>
      </nv-password-strength>
    </div>

    <div class="col-12 d-flex flex-wrap justify-content-md-end">
      <button
        class="btn-light mt-1 mt-md-0 col-12 col-md-auto order-last order-md-first"
        nv-button
        data-cy="cancel"
        type="button"
        [routerLink]="loginDetailPath"
      >
        {{ 'BTN.CANCEL' | translate }}
      </button>
      <button class="col-12 col-md-auto ms-md-1" nv-button data-cy="update-login-details" type="submit">
        {{ 'BTN.SAVE' | translate }}
      </button>
    </div>
  </form>
</nv-card>

<nv-modal #modal>
  <ng-template modalContent>
    <div class="row justify-content-center align-items-center" data-cy="success-modal-content">
      <div class="col-12 text-secondary text-center display-1">
        <i class="fal fa-lock-alt"></i>
      </div>
      <div class="col-12 text-center mb-2">
        <p>{{ 'LOGIN-DETAILS.PASSWORD_CHANGE_SUCCESS' | translate }}</p>
      </div>
    </div>
  </ng-template>
  <ng-content></ng-content>
</nv-modal>
