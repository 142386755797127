<section class="content">
  <h3 class="mb-3">{{ 'APP.TITLE' | translate }}</h3>
  <nv-card class="border-primary-1 mb-3">
    <div class="row">
      <div class="col-12">
        <h3>{{ 'EXIT_PAGE.TITLE' | translate }}</h3>
        <p>{{ 'EXIT_PAGE.TEXT.REASON' | translate }}</p>
        <p>{{ 'EXIT_PAGE.TEXT.EXCUSE' | translate }}</p>
      </div>
      <div *ngIf="showContactUsButton" class="col-12">
        <p>{{ 'EXIT_PAGE.TEXT.APPROACH' | translate }}</p>
        <a
          [nvActionName]="'CONSULTANT.PHONE_NUMBER' | translate"
          class="btn-block d-md-inline-block me-0 me-md-3 mb-2 mb-md-0 w-auto"
          color="secondary"
          href="tel:+498005318119"
          nv-button
        >
          <i class="fas fa-phone fa-rotate-90 me-1"></i>{{ 'CONSULTANT.PHONE_NUMBER' | translate }}
        </a>
      </div>
    </div>
  </nv-card>
</section>
