<div *ngIf="visible" class="logout-automatic-message shadow mb-3 mt-2">
  <div class="row pb-1">
    <div class="col-1 d-flex ps-6">
      <div>
        <i class="fas fa-info-circle nv-info"></i>
      </div>
    </div>
    <div class="col-11">
      <p class="mb-0">
        {{ 'APP.PAGE.LOGOUT.TITLE' | translate }}
      </p>
      <button type="button" class="btn btn-light nv-dismiss ps-2" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true" class="far fa-times"></span>
      </button>
    </div>
  </div>
</div>
