<ng-container *ngIf="isLoggedIn$ | async">
  <b *ngIf="showTitle"
    ><a class="cursor-pointer" data-cy="logout-btn" (click)="logOut()"
      ><i class="me-1 far fa-sign-out"></i>{{ 'BTN.LOGOUT' | translate }}</a
    ></b
  >
  <button *ngIf="enableUserLogin && !showTitle" nv-button color="primary" class="rounded-circle p-0" (click)="logOut()">
    <i class="far fa-sign-out"></i>
  </button>
</ng-container>
