<nv-wizard-header *ngIf="appConfigService.config.showHeaderWithFixedNavigationBar"></nv-wizard-header>
<section class="content">
  <h3 class="mb-3">{{ 'APP.TITLE' | translate }}</h3>
  <div class="row mb-3 justify-content-center">
    <div class="col-12">
      <h3 class="mb-3">{{ 'ERROR_PAGE.TITLE' | translate }}</h3>

      <button nv-button type="submit" [routerLink]="[backToStartLink]">
        {{ 'BTN.BACK_TO_START' | translate }}
      </button>
    </div>
  </div>
</section>
