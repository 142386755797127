import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';

import {
  generateUserId,
  getAgeSpan,
  getGender,
  TealiumConfig,
  TealiumUtagService,
  TrackingConfig,
  TrackingService,
  TrackingStore,
} from '@nbg-digital/shared/util-tracking';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { RiskAssessmentFacade } from '@nbg-digital/risk-assessment/api';
import { ApplicationFacade } from '@nbg-digital/application/api';
import { combineLatest, of } from 'rxjs';

@Injectable()
export class DkrTrackingService extends TrackingService {
  protected subBroker$ = this.pricingFacade?.subBroker$;
  protected processId$ = this.pricingFacade?.offerId$;
  protected productCategory$ = this.pricingFacade?.pricing$.pipe(map((params) => params.tariff));
  protected productId$ = this.pricingFacade?.pricing$.pipe(map((params) => params.tariffId));
  protected orderValue$ = combineLatest([
    this.pricingFacade?.pricing$,
    this.riskAssessmentFacade?.riskAssessmentResult$,
  ]).pipe(map(([pricing, riskAssessment]) => (riskAssessment?.adjustedPrice || pricing.price) * 12));
  protected orderDetails$ = this.pricingFacade?.pricing$.pipe(map((params) => params.tariff));
  protected orderId$ = this.pricingFacade?.offerId$;
  protected userId$ = this.applicationFacade?.user$.pipe(map((user) => generateUserId(user?.email)));
  protected userAge$ = this.pricingFacade?.selectedBirthdate$.pipe(map((birthdate) => getAgeSpan(birthdate)));
  protected userGender$ = this.pricingFacade?.pricing$.pipe(map((params) => getGender(params.salutation)));
  protected userOccupation$ = this.pricingFacade?.pricing$.pipe(map((params) => params.profession?.jobName));
  protected userGraduationLevel$ = this.pricingFacade?.pricing$.pipe(
    switchMap((params) =>
      params.profession?.graduation
        ? this.translate.get(`OPTION.EDUCATION_LEVEL.${params.profession?.graduation}`)
        : of(null)
    )
  );
  protected userOfficeWork$ = this.pricingFacade?.pricing$.pipe(
    switchMap((params) =>
      params.profession?.officeQuota
        ? this.translate.get(`OPTION.OFFICE_SHARE.${params.profession?.officeQuota}`)
        : of(null)
    )
  );

  constructor(
    router: Router,
    route: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
    tealium: TealiumUtagService,
    @Inject('trackingConfig') config: TrackingConfig,
    @Inject('tealiumConfig') tealiumConfig: TealiumConfig,
    @Optional() private pricingFacade: PricingFacade,
    @Optional() private riskAssessmentFacade: RiskAssessmentFacade,
    @Optional() private applicationFacade: ApplicationFacade,
    private translate: TranslateService,
    private trackingStore: TrackingStore
  ) {
    super(router, route, breakpointObserver, tealium, config, tealiumConfig, trackingStore);
  }
}
