<nv-link link="['..']" type="breadcrumb" class="col-12 col-md-4 d-block d-lg-none p-0 mb-2"
  >{{ 'LOGIN-DETAILS.BACK_TO_PROFILE' | translate }}
</nv-link>
<nv-card>
  <h3>{{ 'LOGIN-DETAILS.TITLE' | translate }}</h3>
  <div class="row">
    <div class="form-group col-12 col-lg-6">
      <p class="text mb-0">{{ 'LABEL.USERNAME' | translate }}</p>
      <p class="text-muted">{{ (user$ | async)?.username }}</p>
    </div>
    <div class="form-group col-12 col-lg-6">
      <button nv-button class="col-12 col-md-auto" data-cy="change-username" [routerLink]="usernameChangeUrl">
        {{ 'BTN.CHANGE_USERNAME' | translate }}
      </button>
    </div>
    <hr class="form-group col-12" />
    <div class="form-group col-12 col-lg-6">
      <p class="text mb-0">{{ 'LABEL.PASSWORD' | translate }}</p>
      <p class="text-muted">************</p>
    </div>
    <div class="form-group col-12 col-lg-6">
      <button nv-button class="col-12 col-md-auto" data-cy="change-password" [routerLink]="passwordChangeUrl">
        {{ 'BTN.CHANGE_PASSWORD' | translate }}
      </button>
    </div>
  </div>
</nv-card>
