<nv-card class="mb-3">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 text-secondary text-center display-1"><i class="fal fa-unlock-alt"></i></div>
    <div class="col-12 text-center mb-2">
      <h2>{{ 'PASSWORD_FORGOT.TITLE' | translate }}</h2>
      <p>{{ 'PASSWORD_FORGOT.MESSAGE' | translate }}</p>
    </div>
    <div class="col-12">
      <form nv-form [formGroup]="passwordForgotForm" (submit)="submit($event)">
        <div class="row mb-2">
          <div class="form-group col-12 col-md-6">
            <div class="form-floating">
              <input
                nvInput
                type="text"
                id="first-name"
                data-cy="first-name"
                name="firstName"
                formControlName="firstName"
                autocomplete="given-name"
                [placeholder]="'PASSWORD_FORGOT.LABEL.FIRST_NAME' | translate"
              />
              <label for="first-name">{{ 'PASSWORD_FORGOT.LABEL.FIRST_NAME' | translate }}</label>
              <nv-error
                *ngIf="
                  passwordForgotForm.controls['firstName'].errors?.required &&
                  passwordForgotForm.controls['firstName'].touched
                "
                id="first-name-error-required"
                data-cy="first-name-error-required"
              >
                {{ 'PASSWORD_FORGOT.ERROR.REQUIRED' | translate }}
              </nv-error>
            </div>
          </div>
          <div class="form-group col-12 col-md-6">
            <div class="form-floating">
              <input
                nvInput
                id="last-name"
                data-cy="last-name"
                name="lastName"
                formControlName="lastName"
                autocomplete="family-name"
                [placeholder]="'PASSWORD_FORGOT.LABEL.LAST_NAME' | translate"
              />
              <label for="last-name">{{ 'PASSWORD_FORGOT.LABEL.LAST_NAME' | translate }}</label>
              <nv-error
                *ngIf="
                  passwordForgotForm.controls['lastName'].errors?.required &&
                  passwordForgotForm.controls['lastName'].touched
                "
                id="last-name-error-required"
                data-cy="last-name-error-required"
                >{{ 'PASSWORD_FORGOT.ERROR.REQUIRED' | translate }}</nv-error
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <div class="form-floating">
              <input
                nvInput
                type="text"
                id="email"
                data-cy="email"
                name="email"
                formControlName="email"
                autocomplete="email"
                [placeholder]="'PASSWORD_FORGOT.LABEL.EMAIL' | translate"
              />
              <label for="email">{{ 'PASSWORD_FORGOT.LABEL.EMAIL' | translate }}</label>
              <nv-error
                *ngIf="
                  passwordForgotForm.controls['email'].errors?.required && passwordForgotForm.controls['email'].touched
                "
                id="email-error-required"
                data-cy="email-error-required"
                >{{ 'PASSWORD_FORGOT.ERROR.REQUIRED' | translate }}
              </nv-error>
              <nv-error
                *ngIf="
                  passwordForgotForm.controls['email'].errors?.pattern && passwordForgotForm.controls['email'].touched
                "
                id="email-error-pattern"
                data-cy="email-error-pattern"
              >{{ 'PASSWORD_FORGOT.ERROR.EMAIL_PATTERN' | translate }}
              </nv-error>
            </div>
            <div *ngIf="showInvalidCredentialsFeedback" class="row">
              <div class="col">
                <nv-error id="invalid-credentials-feedback" data-cy="invalid-credentials-feedback" class="d-block">
                  {{ 'PASSWORD_FORGOT.ERROR.INVALID_CREDENTIALS' | translate }}</nv-error
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-md-end text-center">
            <button nv-button type="submit" class="col-12 col-md-auto" data-cy="send-new-password-link">
              {{ 'PASSWORD_FORGOT.BTN.SEND' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</nv-card>

<nv-modal #modalLinkMailed (closed)="onModalLinkMailedClosed()">
  <ng-template modalContent>
    <form nv-form (submit)="submitModalLinkMailed($event)">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 text-secondary text-center display-1"><i class="fal fa-envelope-open-text"></i></div>
        <div class="col-12 text-center mb-2">
          <h2>{{ 'PASSWORD_FORGOT.LINK_MAILED.TITLE' | translate }}</h2>
          <p [innerHTML]="'PASSWORD_FORGOT.LINK_MAILED.MESSAGE' | translate"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button nv-button data-cy="send-new-password-link" type="submit" class="btn-block col-12 col-md-auto">
            {{ 'PASSWORD_FORGOT.LINK_MAILED.BTN.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</nv-modal>
