import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  InsuranceApplicationWizardComponent,
  NvErrorPageComponent,
  NvRejectionPageComponent,
} from '@nbg-digital/wizard/shell';
import { NvServiceErrorPageComponent } from '@nbg-digital/ui-dpl-components';

export const APPLICATION_PATH = 'antrag';
export const PRICING_PATH = 'tarifierung';
export const REGISTRATION_PATH = `${APPLICATION_PATH}/registrierung`;
export const CONCLUSION_PATH = `abschluss`;
export const SUMMARY_PATH = `${CONCLUSION_PATH}/zusammenfassung`;
export const TRANSMISSION_PATH = `${CONCLUSION_PATH}/abgeschickt`;
export const SUCCESS_PATH = `${CONCLUSION_PATH}/erfolg`;
export const PENDING_PATH = `${CONCLUSION_PATH}/pruefung`;
export const RISK_ASSESSMENT_PATH = 'risikopruefung';
export const REJECTION_PATH = 'ablehnung';
export const REENTRY_PATH = 'wiedereinstieg/:offerId';
export const AGREEMENT_WITHDRAWAL_PATH = 'wiedereinstieg/widerruf/:offerId';
export const BROKER_ENTRY_PATH = 'broker/:brokerId';
export const ERROR_PATH = 'error';
export const SERVICE_ERROR_PATH = 'service-error';

const routes: Routes = [
  {
    path: '',
    component: InsuranceApplicationWizardComponent,
    children: [
      {
        path: '',
        redirectTo: PRICING_PATH,
        pathMatch: 'full',
      },
      {
        path: PRICING_PATH,
        loadChildren: () => import('@nbg-digital/pricing/feature-dkr').then((m) => m.PricingFeatureDkrModule),
      },
      {
        path: APPLICATION_PATH,
        loadChildren: () => import('@nbg-digital/application/feature-dkr').then((m) => m.ApplicationFeatureDkrModule),
      },
      {
        path: RISK_ASSESSMENT_PATH,
        loadChildren: () =>
          import('@nbg-digital/risk-assessment/feature-wizard').then((m) => m.RiskAssessmentFeatureWizardModule),
      },
      {
        path: REGISTRATION_PATH,
        loadChildren: () => import('@nbg-digital/user-management/feature').then((m) => m.RegistrationModule),
        data: {
          navigation: {
            previousLink: '/antrag/bezahlung',
            nextLink: '/abschluss',
          },
        },
      },
      {
        path: REENTRY_PATH,
        loadChildren: () => import('@nbg-digital/session-management/feature').then((m) => m.ReentryModule),
      },
      {
        path: BROKER_ENTRY_PATH,
        resolve: { product: () => 'dkr' },
        loadChildren: () => import('@nbg-digital/session-management/feature').then((m) => m.BrokerEntryModule),
      },
      {
        path: CONCLUSION_PATH,
        loadChildren: () =>
          import('@nbg-digital/policy-conclusion/feature-dkr').then((m) => m.PolicyConclusionFeatureDkrModule),
      },
    ],
  },
  {
    path: TRANSMISSION_PATH,
    loadChildren: () => import('@nbg-digital/policy-conclusion/feature').then((m) => m.TransmissionModule),
  },
  {
    path: SUCCESS_PATH,
    loadChildren: () => import('@nbg-digital/policy-conclusion/feature').then((m) => m.ApplicationSuccessModule),
  },
  {
    path: PENDING_PATH,
    loadChildren: () => import('@nbg-digital/policy-conclusion/feature').then((m) => m.ApplicationPendingModule),
  },
  {
    path: ERROR_PATH,
    component: NvErrorPageComponent,
    data: {
      tracking: {
        page: {
          type: 'Error',
          name: 'Error',
          category: 'Error',
        },
      },
    },
  },
  {
    path: SERVICE_ERROR_PATH,
    component: NvServiceErrorPageComponent,
    data: {
      tracking: {
        page: {
          type: 'Error',
          name: 'ServiceError',
          category: 'Error',
        },
      },
    },
  },
  {
    path: AGREEMENT_WITHDRAWAL_PATH,
    loadChildren: () =>
      import('@nbg-digital/session-management/feature').then((m) => m.StorageAgreementWithdrawalModule),
  },
  {
    path: REJECTION_PATH,
    component: NvRejectionPageComponent,
    data: {
      tracking: {
        page: {
          type: 'Ablehnung',
          name: 'Ablehnung',
          category: 'Ablehnung',
        },
      },
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
